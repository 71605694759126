.root {
  background: var(--color-bg-sidebar);
  min-width: 150px;
  position: relative;
}

.logoPlaceholder {
  height: 15px;
  @media (max-width: 768px) {
    display: none;
  }
}

.rows {
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    overflow: scroll;
  }
}

/* a router link */
.row {
  color: var(--color-text);
  text-decoration: none;

  display: flex;
  align-items: center;
  padding: 6px 16px;
  @media (--breakpoint-not-small) {
    padding: 8px 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }

  svg {
    color: var(--color-icon);
    width: 22px;
    height: 22px;

    @media (--breakpoint-not-small) {
      width: 24px;
      height: 24px;
    }
  }
}

.rowActive {
  background: var(--color-sb-active-row-bg);

  @media (max-width: 768px) {
    background: none;
    border-bottom: 2px solid #387cec;
  }
}

.label {
  padding-left: 14px;
  font-size: 0.75em;
  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 5px;
  }

  @media (--breakpoint-not-small) {
    font-size: 1em;
  }
}

.footer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .footer {
    display: none;
  }
}

.iconWrapper {
  --sz: 40px;

  width: var(--sz);
  height: var(--sz);
  display: flex;
  justify-content: center;
  align-items: center;

  outline: none;
  padding: 5px;
  color: var(--color-text);
  border-radius: 100%;
  border: 1px solid transparent;
}
.iconWrapper:hover {
  opacity: 0.6;
}
.iconWrapper:focus {
  border-color: var(--color-focus-blue);
}

.themeSwitchContainer {
  appearance: none;
  user-select: none;
  background: none;
  cursor: pointer;
}
