.input {
  -webkit-appearance: none;
  background-color: var(--color-input-bg);
  background-image: none;
  border-radius: 4px;
  border: 1px solid var(--color-input-border);
  box-sizing: border-box;
  color: #c1c1c1;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  outline: none;
  padding: 0 15px;
  width: 100%;
}
.input:focus {
  box-shadow: rgba(66, 153, 225, 0.6) 0px 0px 0px 3px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
